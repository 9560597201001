import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { Box, Typography } from "@material-ui/core";
import { useDeviceTypes, LandingBenefits } from "halifax";
import "./styles.scss";
import * as constants from "./constants";
import { DesktopCarSearchControl } from "./components/DesktopCarSearchControl";
import { MobileCarSearchControl } from "./components/MobileCarSearchControl";
import { CAR_SEARCH_TITLE, PORTAL_TITLE } from "../../lang/textConstants";
import {
  AVAILABLE,
  CASH_VALUEPROP_A,
  CASH_VALUEPROP_B,
  CASH_VALUEPROP_C,
  CASH_VALUEPROP_EXPERIMENT,
  CASH_VALUEPROP_VARIANTS,
  CONTROL,
  getExperimentVariant,
  getExperimentVariantCustomVariants,
  MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
  MOBILE_HOMESCREEN_REDESIGN_V2,
  MOBILE_HOMESCREEN_REDESIGN_V3,
  MOBILE_HOMESCREEN_REDESIGN_VARIANTS,
  RECENTLY_VIEWED_V2_CARS,
  useExperiments,
} from "../../context/experiments";
import { CarsSearchConnectorProps } from "./container";
import clsx from "clsx";
import { MobileCarSearchControlV2 } from "./components/MobileCarSearchControlV2";
import { MobileCarSearchControlV3 } from "./components/MobileCarSearchControlV3";
import {
  AUTOCOMPLETE_LOCATION_SELECTED,
  AutocompleteLocationSelectedProperties,
  CALENDAR_DATES_SELECTED,
  CalendarDatesSelectedProperties,
  CallState,
  CarEntryTypeEnum,
  LAUNCHED_MOBILE_CALENDAR,
  LAUNCHED_MOBILE_LOCATION_SCREEN,
  LaunchedMobileCalendarProperties,
  LaunchedMobileLocationScreenProperties,
  RecentCarSearch,
  Tenant,
} from "redmond";
import { transformToStringifiedAvailabilityQuery } from "../availability/utils/queryStringHelpers";
import { PATH_AVAILABILITY } from "../../utils/paths";
import { useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import { trackEvent } from "../../api/v1/analytics/trackEvent";
import dayjs from "dayjs";
import { MobileCarSearchStep } from "./reducer";
import queryStringParser from "query-string";
import { config } from "../../api/config";
import {
  CORP_HOMEPAGE_SUBTITLE,
  CORP_HOMEPAGE_TITLE,
  CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT,
  getShowLaunchBanner,
  isCorpTenant,
} from "@capone/common";
import { ClientContext } from "../../App";
import { useExperimentsById } from "@capone/experiments";

export const CarsSearch = (props: CarsSearchConnectorProps) => {
  const {
    fetchRewardsAccounts,
    rewardsAccounts,
    largestValueAccount,
    fetchRewardsAccountsCallState,
    currentStep,
    resetCallStates,
    setMobileCarSearchStep,
    listPaymentMethods,
  } = props;
  const [recentSearches, setRecentSearches] = useState<RecentCarSearch[]>([]);
  const { matchesMobile } = useDeviceTypes();
  const history = useHistory();
  const { sessionInfo } = useContext(ClientContext);
  const expState = useExperiments();

  const cashValuePropVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    CASH_VALUEPROP_EXPERIMENT,
    CASH_VALUEPROP_VARIANTS
  );

  const recentlyViewedV2Cars = getExperimentVariant(
    expState.experiments,
    RECENTLY_VIEWED_V2_CARS
  );

  const isRecentlyViewedCarsV2Experiment = useMemo(() => {
    return recentlyViewedV2Cars === AVAILABLE;
  }, [recentlyViewedV2Cars]);

  useEffect(() => {
    document.title = CAR_SEARCH_TITLE;
    fetchRewardsAccounts(false, sessionInfo);
    listPaymentMethods();
    setTimeout(() => window.scrollTo(0, 0), 0);

    return () => {
      document.title = PORTAL_TITLE;
    };
  }, []);

  useEffect(() => {
    if (matchesMobile) {
      const { entryType } = queryStringParser.parse(history.location.search);
      const properties: LaunchedMobileLocationScreenProperties = {
        funnel: "cars",
        url: window.location.pathname,
        entry_type: entryType as string,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_LOCATION_SCREEN, properties });

      setMobileCarSearchStep(MobileCarSearchStep.LocationSearch);
      resetCallStates();
    }
  }, [matchesMobile]);

  const handleSelectLocation = useCallback((value: any) => {
    if (value?.label) {
      const properties: AutocompleteLocationSelectedProperties = {
        funnel: "cars",
        url: window.location.pathname,
        autocomplete_value: value.label,
      };
      trackEvent({ eventName: AUTOCOMPLETE_LOCATION_SELECTED, properties });
    }
  }, []);

  const handleSelectDates = useCallback(
    (pickUp: Date | null, dropOff: Date | null) => {
      if (pickUp && dropOff) {
        const properties: CalendarDatesSelectedProperties = {
          funnel: "cars",
          url: window.location.pathname,
          start_date: dayjs(pickUp).format("YYYY-MM-DD"),
          end_date: dayjs(dropOff).format("YYYY-MM-DD"),
        };
        trackEvent({ eventName: CALENDAR_DATES_SELECTED, properties });
      }
    },
    []
  );

  useEffect(() => {
    if (matchesMobile && currentStep === MobileCarSearchStep.CalendarPicker) {
      const properties: LaunchedMobileCalendarProperties = {
        funnel: "cars",
        url: window.location.pathname,
      };
      trackEvent({ eventName: LAUNCHED_MOBILE_CALENDAR, properties });
    }
  }, [matchesMobile, currentStep]);

  useEffect(() => {
    const getRecentSearches = () => {
      try {
        const localStorageItem = localStorage.getItem("recently_searched_cars");

        if (localStorageItem) {
          const parsedSearches: RecentCarSearch[] =
            JSON.parse(localStorageItem);
          const sortedSearches = parsedSearches
            .filter((search) => !!search.pickUpLocationLabel)
            .sort(
              (a, b) =>
                new Date(b.searchDate).getTime() -
                new Date(a.searchDate).getTime()
            );
          setRecentSearches(sortedSearches);
        }
      } catch (e) {
        console.error(`Failed to get recent searches from local storage: ${e}`);
      }
    };

    getRecentSearches();

    addEventListener("update_recently_searched_cars", getRecentSearches);

    return () =>
      removeEventListener("update_recently_searched_cars", getRecentSearches);
  }, []);

  const onRecentSearchClick = useCallback(
    (search: RecentCarSearch) => {
      history.push(
        `${PATH_AVAILABILITY}${transformToStringifiedAvailabilityQuery(
          search.dropOffDate,
          search.dropOffTime,
          search.dropOffLocation,
          search.pickUpDate,
          search.pickUpTime,
          search.pickUpLocation,
          search.driverAge,
          CarEntryTypeEnum.RECENTLY_SEARCH_AUTOCOMPLETE
        )}`
      );
    },

    [history]
  );

  const hasOnlyCashCards = useMemo(
    () =>
      rewardsAccounts?.length > 0 &&
      rewardsAccounts.every(
        (account) => account.rewardsBalance.currency.toLowerCase() === "cash"
      ),
    [rewardsAccounts]
  );

  const benefitsVariant = useMemo(() => {
    switch (cashValuePropVariant) {
      case CONTROL:
      default:
        return [];
      case CASH_VALUEPROP_A:
        return constants.CASH_CARD_BENEFITS.VARIANT_A;
      case CASH_VALUEPROP_B:
        return constants.CASH_CARD_BENEFITS.VARIANT_B;
      case CASH_VALUEPROP_C:
        return constants.CASH_CARD_BENEFITS.VARIANT_C;
    }
  }, [cashValuePropVariant]);

  const mobileHomeScreenVariant = getExperimentVariantCustomVariants(
    expState.experiments,
    MOBILE_HOMESCREEN_REDESIGN_EXPERIMENT,
    MOBILE_HOMESCREEN_REDESIGN_VARIANTS
  );

  const hasEarnToDisplay = useMemo(() => {
    return !!largestValueAccount && !!largestValueAccount.earn.carsMultiplier;
  }, [largestValueAccount]);

  const showDBCCustomHeader =
    useExperimentsById("corp-custom-header-logo-dbc")?.variant === "available";

  const getTitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return showDBCCustomHeader
        ? CORP_HOMEPAGE_TITLE_DBC_EXPERIMENT
        : CORP_HOMEPAGE_TITLE;
    } else if (
      fetchRewardsAccountsCallState === CallState.Success &&
      hasEarnToDisplay
    ) {
      return constants.EARN_ENHANCEMENT_TITLE(
        largestValueAccount.earn.carsMultiplier,
        largestValueAccount.productDisplayName,
        largestValueAccount.rewardsBalance.currencyDescription ??
          largestValueAccount.rewardsBalance.currency
      );
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      if (
        largestValueAccount?.productDisplayName
          .toLowerCase()
          .includes("paradise")
      ) {
        return <span className="font-regular">{constants.PARADISE_TITLE}</span>;
      } else {
        return <span className="font-regular">{constants.TITLE}</span>;
      }
    } else {
      return <Skeleton className="title-loading" />;
    }
  };

  const getSubtitleToDisplay = (tenant: Tenant) => {
    if (isCorpTenant(tenant)) {
      return CORP_HOMEPAGE_SUBTITLE;
    } else if (
      fetchRewardsAccountsCallState === CallState.Success ||
      fetchRewardsAccountsCallState === CallState.Failed
    ) {
      return constants.SUBTITLE;
    } else {
      return <Skeleton className="subtitle-loading" />;
    }
  };

  const showCorpLaunchBanner = getShowLaunchBanner(sessionInfo);

  return (
    <>
      {matchesMobile ? (
        <Box
          className={clsx("mobile-car-search-root", {
            "redesign-v2":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2,
            "redesign-v3":
              mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3,
          })}
        >
          <Box className="car-search-container-mobile">
            {mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V2 ? (
              <>
                <MobileCarSearchControlV2
                  recentSearches={
                    isRecentlyViewedCarsV2Experiment
                      ? recentSearches
                      : undefined
                  }
                  onRecentSearchClick={onRecentSearchClick}
                  onSelectLocation={handleSelectLocation}
                  onSelectDates={handleSelectDates}
                />
                <Box className="img-container"></Box>
              </>
            ) : mobileHomeScreenVariant === MOBILE_HOMESCREEN_REDESIGN_V3 ? (
              <MobileCarSearchControlV3
                recentSearches={
                  isRecentlyViewedCarsV2Experiment ? recentSearches : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                onSelectLocation={handleSelectLocation}
                onSelectDates={handleSelectDates}
              />
            ) : (
              <MobileCarSearchControl
                recentSearches={
                  isRecentlyViewedCarsV2Experiment ? recentSearches : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
                onSelectLocation={handleSelectLocation}
                onSelectDates={handleSelectDates}
              />
            )}
          </Box>
        </Box>
      ) : (
        <Box className={clsx("car-search-root", config.TENANT)}>
          <Box className={clsx("car-search-container-desktop", config.TENANT)}>
            <Box
              className={clsx("car-search-titles-and-fields-wrapper", {
                "not-live": showCorpLaunchBanner,
              })}
            >
              <Typography variant="h1" className="car-search-heading">
                {getTitleToDisplay(config.TENANT)}
              </Typography>
              <Typography variant="h5" className="car-search-subtitle">
                {getSubtitleToDisplay(config.TENANT)}
              </Typography>
              <DesktopCarSearchControl
                recentSearches={
                  isRecentlyViewedCarsV2Experiment ? recentSearches : undefined
                }
                onRecentSearchClick={onRecentSearchClick}
              />
            </Box>
          </Box>
          {cashValuePropVariant !== CONTROL && hasOnlyCashCards && (
            <LandingBenefits
              title={constants.CASH_CARD_BENEFITS_TITLE(
                rewardsAccounts?.[0]?.productDisplayName
              )}
              benefits={benefitsVariant}
            />
          )}
        </Box>
      )}
    </>
  );
};
