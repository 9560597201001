import React, { useState, useEffect, useContext, useMemo } from "react";
import { Box, Typography } from "@material-ui/core";
import {
  ContactInfoWorkflow,
  IContactInfo,
  TravelerSelectStep,
  ContactInfoStep,
  Header,
  MobilePopoverCard,
  BackButton,
  MobileFloatingButton,
  LoadingPopup,
  B2BSpinner,
  NotificationBanner,
  BannerSeverity,
  Icon,
  IconName,
  emailRegex,
  phoneRegex,
  ApprovalReasonWorkflow,
  ApprovalBanner,
  ApprovalReasonStep,
  formatDateTimeWithTimeZone,
  LaunchBanner,
  InformationalModal as TreesModal,
  TreesConfirmation,
  TravelWalletSingleSelectionStep,
} from "halifax";
import {
  ADD_CONTACT_INFO,
  CarsBookErrorEnum,
  ITravelerStepErrors,
  InvalidEnum,
  ConnectionResultEnum,
  CorpSessionInfo,
  CorpCarPriceQuote,
  PaymentError,
} from "redmond";
import { RouteComponentProps } from "react-router";
import { setSubmitForApprovalFailure } from "../../../actions/actions";

import "./styles.scss";
import clsx from "clsx";
import { MobileCarBookWorkflowConnectorProps } from "./container";
import {
  PriceBreakdown,
  CarBookPassengerSelection,
  CarBookMobileButton,
  CarBookSummaryPanel,
  PriceBreakdownDropdown,
  AGENT_FEE,
} from "../../../components";
import {
  CONTACT_INFO_SAVE,
  CONTINUE,
  CONTACT_INFO_TITLE,
  CONTACT_INFO_SUBTITLE,
  REVIEW_MY_TRIP_TEXT,
  PRICE_QUOTE_MESSAGE,
  TITLE,
  SUBTITLE,
  UNDER_25_WARNING,
  UPDATE_DRIVER,
} from "./textConstants";
import { PATH_SHOP, PATH_BOOK_CONFIRMATION } from "../../../../../utils/paths";
import { ClientContext } from "../../../../../App";
import { CarShopSmallMap } from "../../../../shop/components/CarShopSmallMap/component";
import { trackEvent } from "../../../../../api/v1/analytics/trackEvent";
import { fetchCustomerDetails } from "../../../../../api/v1/customer/fetchCustomerDetails";
import submitForApproval from "../../../../../api/v1/book/book-flow/submitForApproval";
import {
  AVAILABLE,
  CREDIT_OFFER_STACKING_V1,
  getExperimentVariant,
  TRAVEL_CREDIT_HISTORY_EXPERIMENT,
  TRAVEL_WALLET_CREDITS_EXPERIMENT,
  TRAVEL_WALLET_OFFER_EXPERIMENT,
  TREES_MODAL_EXPERIMENT,
  useExperiments,
} from "../../../../../context/experiments";
import {
  HertzLoyaltyStep,
  HertzLoyaltyWorkflow,
} from "../../MobileHertzLoyaltyWorkflow";
import { PaymentCard } from "../../PaymentCard";
import {
  TREES_BOLDED_MODAL_CTA_TEXT,
  TREES_MODAL_CTA_TEXT,
  TREES_MODAL_HEADER,
  TREES_MODAL_SUBTITLE,
  TREES_MODAL_TITLE,
} from "../../capone/MobileCarBookWorkflow/textConstants";
import { ProductError } from "@b2bportal/purchase-api";
import { useExperimentIsVariant } from "@capone/experiments";
import { TravelOfferSelection } from "../../TravelOfferSelection";

export interface IMobileCarBookWorkflowProps
  extends RouteComponentProps,
    MobileCarBookWorkflowConnectorProps {}

enum MobileCarBookWorkflowStep {
  TravelerInformation,
  ContactInformation,
  HertzRewards,
  TravelOfferSelection,
  RewardsAndPayment,
  ApprovalReason,
  Review,
}

export const CorpMobileCarBookWorkflow = ({
  priceQuoteInProgress,
  hasNoUserPassengers,
  schedulePriceQuote,
  history,
  reservation,
  isBookingValid,
  setContactInfo,
  confirmationEmail,
  confirmationPhoneNumber,
  priceDifferenceAcknowledged,
  scheduleBook,
  selectedVehicle,
  driverAge,
  canBook,
  isHertzProvider,
  priceQuoteErrors,
  confirmationDetailsErrors,
  hertzLoyaltyRewardsNumber,
  offers,
  errorTitles,
  resetPaymentCardSelectedAccounts,
  rewardsPaymentAccountReferenceId,
  setPriceQuote,
  priceQuote,
  setSubmitForApproval,
  driver,
  paymentInfo,
  carAvailabilityRequest,
  useV1PurchaseFlow,
  credit,
  isTravelWalletPaymentOnly,
  fetchApplicableTravelWalletItems,
  fetchTravelWalletCreditHistory,
}: IMobileCarBookWorkflowProps) => {
  const isInPolicy =
    // TODO(replat): add back after BE work is done
    //   priceQuote
    // ? (priceQuote.vehAvail as CorpVehicleAvail).corporateTravel.policyCompliance
    //     .isInPolicy
    // :
    selectedVehicle?.corporateTravel?.policyCompliance.isInPolicy ?? true;

  const clientContext = useContext(ClientContext);
  const { sessionInfo, isAgentPortal, isAutoApprovalEnabled, policies } =
    clientContext;
  // TODO: remove this when we decide if using text message - https://hopchat.slack.com/archives/C01FX0M22MV/p1620146159000600
  const [contactInfo, setContact] = useState<IContactInfo | null>({
    phoneNumber: confirmationPhoneNumber || "",
    email: confirmationEmail || sessionInfo?.userInfo?.email || "",
  });
  const [checkoutStep, setCheckoutStep] =
    useState<MobileCarBookWorkflowStep>(0);
  const [travelerWorkflowStep, setTravelerWorkflowStep] =
    useState<TravelerSelectStep>(TravelerSelectStep.Main);
  const [contactInfoStep, setContactInfoStep] = useState<ContactInfoStep>(
    ContactInfoStep.Main
  );
  const [customerDetailsLoading, setCustomerDetailsLoading] =
    React.useState<boolean>(true);
  const [openPaymentCard, setOpenPaymentCard] = useState<boolean>(false);
  const [hertzLoyaltyStep, setHertzLoyaltyStep] = useState<HertzLoyaltyStep>(
    HertzLoyaltyStep.Main
  );
  const [travelWalletSelectStep, setTravelWalletSelectStep] =
    useState<TravelWalletSingleSelectionStep>(
      TravelWalletSingleSelectionStep.Main
    );
  const [treeModalOpen, setTreeModalOpen] = useState(false);
  const [approvalReasonStep, setApprovalReasonStep] =
    useState<ApprovalReasonStep>(ApprovalReasonStep.Main);

  const [requestReason, setRequestReason] = React.useState("");

  const onSubmitForApprovalFailure = () =>
    setSubmitForApprovalFailure({
      Invalid: InvalidEnum.Missing,
      ConnectionResult: ConnectionResultEnum.Invalid,
    });

  const onSubmitForApproval = async () => {
    if (selectedVehicle && driver) {
      const request = {
        approvalReason: requestReason || "",
        cancellationPolicy: selectedVehicle.cancellationPolicy,
        contactEmail: contactInfo?.email || "",
        driver,
        dropOff: selectedVehicle.dropOff,
        paymentInfo: paymentInfo?.payments[0],
        pickUp: selectedVehicle.pickUp,
        policyCompliance:
          // (priceQuote?.vehAvail as CorpVehicleAvail).corporateTravel
          //   .policyCompliance ||
          selectedVehicle?.corporateTravel?.policyCompliance,
        rentalRate: priceQuote?.vehAvail?.rentalRate,
        shopRequest: {
          opaqueShopRequestContext: selectedVehicle.opaqueShopRequestContext,
          opaqueShopRequestVehicle: selectedVehicle.opaqueShopRequestVehicle,
        },
        supplier: (priceQuote as CorpCarPriceQuote)?.dataList?.suppliers[0],
        totalPrice: selectedVehicle.totalCharge.fiat,
        vehicle: selectedVehicle.vehicle,
        vendor: (priceQuote as CorpCarPriceQuote)?.dataList?.vendors[0],
        availabilityRequest: carAvailabilityRequest,
        requestedAt: formatDateTimeWithTimeZone(new Date()),
        isApprovalRequired: !isAutoApprovalEnabled && !isApprovalsV2Enabled,
      };

      try {
        if (isAutoApprovalEnabled || isApprovalsV2Enabled) {
          const requestId = await submitForApproval(request);
          requestId ? onBookCar() : onSubmitForApprovalFailure();
        } else {
          setSubmitForApproval(request);
        }
      } catch (error) {
        console.error(error);
        onSubmitForApprovalFailure();
      }
    } else {
      onSubmitForApprovalFailure();
    }
  };

  const openApprovalReasonWorkflow = () => {
    setApprovalReasonStep(ApprovalReasonStep.ApprovalReason);
  };

  const [showErrors, setShowErrors] = useState<ITravelerStepErrors>({
    loyaltyNumber: false,
  });

  const expState = useExperiments();

  const treesModalExperiment = getExperimentVariant(
    expState.experiments,
    TREES_MODAL_EXPERIMENT
  );
  const isTreesModalExperiment = useMemo(
    () => treesModalExperiment === AVAILABLE,
    [treesModalExperiment]
  );

  const travelWalletOffer = getExperimentVariant(
    expState.experiments,
    TRAVEL_WALLET_OFFER_EXPERIMENT
  );
  const isTravelWalletOfferExperiment = React.useMemo(
    () => travelWalletOffer === AVAILABLE,
    [travelWalletOffer]
  );

  const isTravelWalletCreditsExperiment = useExperimentIsVariant(
    TRAVEL_WALLET_CREDITS_EXPERIMENT,
    AVAILABLE
  );

  const isCreditAndOfferStackingExperimentV1 = useExperimentIsVariant(
    CREDIT_OFFER_STACKING_V1,
    AVAILABLE
  );

  const isTravelCreditHistoryExperiment = useExperimentIsVariant(
    TRAVEL_CREDIT_HISTORY_EXPERIMENT,
    AVAILABLE
  );

  const isApprovalsV2Enabled = useExperimentIsVariant(
    "corp-approvals-v2",
    "m2"
  );

  const isShoppingCartGroundEnabled = useExperimentIsVariant(
    "c1-shopping-cart-ground-booking",
    AVAILABLE
  );

  const onBookCar = () => {
    priceDifferenceAcknowledged || canBook
      ? scheduleBook(isAgentPortal ? AGENT_FEE : 0, requestReason)
      : schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, undefined);
  };

  const openCarBookPassengerSelection = () =>
    setTravelerWorkflowStep(TravelerSelectStep.TravelerSelect);

  const openContactInfoWorkflow = () => {
    setContactInfoStep(ContactInfoStep.ContactInfoForm);
  };

  const openPaymentSelection = () => setOpenPaymentCard(true);

  const openHertzRewards = () =>
    setHertzLoyaltyStep(HertzLoyaltyStep.HertzLoyaltyForm);

  const incrementCheckoutStep = () => {
    setCheckoutStep((step) => {
      if (step === MobileCarBookWorkflowStep.ContactInformation) {
        if (!isHertzProvider) {
          if (
            !isTravelWalletCreditsExperiment ||
            isCreditAndOfferStackingExperimentV1 ||
            (!credit && !offers?.length)
          ) {
            return step + 3;
          } else {
            return step + 2;
          }
        }
      }
      if (step === MobileCarBookWorkflowStep.HertzRewards) {
        if (
          !isTravelWalletCreditsExperiment ||
          isCreditAndOfferStackingExperimentV1 ||
          (isTravelWalletCreditsExperiment && !credit && !offers?.length)
        ) {
          return step + 2;
        }
      }
      if (step === MobileCarBookWorkflowStep.TravelOfferSelection) {
        if (isTravelWalletPaymentOnly) {
          return step + 2;
        }
      }
      if (step === MobileCarBookWorkflowStep.RewardsAndPayment) {
        return isInPolicy ? step + 2 : step + 1; // skips approval reason step
      }
      return step === MobileCarBookWorkflowStep.Review ? step : step + 1;
    });
  };

  const handleGoBack = () => {
    switch (checkoutStep) {
      case MobileCarBookWorkflowStep.TravelerInformation:
        history.push(PATH_SHOP);
        break;
      case MobileCarBookWorkflowStep.RewardsAndPayment:
        if (
          !isTravelWalletCreditsExperiment ||
          isCreditAndOfferStackingExperimentV1 ||
          (!credit && !offers?.length)
        ) {
          if (!isHertzProvider) {
            setCheckoutStep((step) => step - 3);
          } else {
            setCheckoutStep((step) => step - 2);
          }
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      case MobileCarBookWorkflowStep.TravelOfferSelection:
        if (!isHertzProvider) {
          setCheckoutStep((step) => step - 2);
        } else {
          setCheckoutStep((step) => step - 1);
        }
        break;
      default:
        setCheckoutStep((step) => {
          if (isInPolicy) {
            return step - 2;
          }
          return step - 1;
        });
        break;
    }
  };

  const closeAllPopovers = () => {
    setTravelerWorkflowStep(TravelerSelectStep.Main);
    setContactInfoStep(ContactInfoStep.Main);
    setHertzLoyaltyStep(HertzLoyaltyStep.Main);
    setOpenPaymentCard(false);
  };

  const startSchedulePriceQuoteAndResetPayment = () => {
    if (isHertzProvider) {
      setCheckoutStep(MobileCarBookWorkflowStep.HertzRewards);
    } else {
      schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, true);
      resetPaymentCardSelectedAccounts();
    }
  };

  React.useEffect(() => {
    if (
      [...priceQuoteErrors, ...confirmationDetailsErrors].some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError).value.value.msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber
          : (error as PaymentError).msg ===
              CarsBookErrorEnum.InvalidLoyaltyNumber;
      })
    ) {
      setCheckoutStep(MobileCarBookWorkflowStep.HertzRewards);
    }

    if (
      [...priceQuoteErrors, ...confirmationDetailsErrors].some((error) => {
        return useV1PurchaseFlow
          ? (error as ProductError).value.value.msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch
          : (error as PaymentError).msg ===
              CarsBookErrorEnum.LoyaltyNameMismatch;
      }) ||
      errorTitles.primaryButtonText === UPDATE_DRIVER
    ) {
      setCheckoutStep(MobileCarBookWorkflowStep.TravelerInformation);
    }
  }, [priceQuoteErrors, confirmationDetailsErrors]);

  useEffect(() => {
    switch (checkoutStep) {
      case MobileCarBookWorkflowStep.TravelerInformation:
        closeAllPopovers();
        openCarBookPassengerSelection();
        break;
      case MobileCarBookWorkflowStep.ContactInformation:
        closeAllPopovers();
        openContactInfoWorkflow();
        break;
      case MobileCarBookWorkflowStep.HertzRewards:
        closeAllPopovers();
        openHertzRewards();
        break;
      case MobileCarBookWorkflowStep.RewardsAndPayment:
        closeAllPopovers();
        openPaymentSelection();
        break;
      case MobileCarBookWorkflowStep.ApprovalReason:
        closeAllPopovers();
        openApprovalReasonWorkflow();
        break;
      default:
        break;
    }
  }, [checkoutStep]);

  // note: it handles the PassengerSelection step logic separately from the previous useEffect;
  // when travelerWorkflowStep is being changed too rapidly (e.g.: changing from Main -> TravelerSelect -> TravelerInfoForm because of hasUserPassengers value)
  // it seems to cause some unexpected behaviours on TravelerSelectWorkflow, which in turn causes the Review screen to be unscrollable
  // https://hopper-jira.atlassian.net/browse/BP-1090
  useEffect(() => {
    if (
      checkoutStep === MobileCarBookWorkflowStep.TravelerInformation &&
      hasNoUserPassengers
    ) {
      setTravelerWorkflowStep(TravelerSelectStep.TravelerInfoForm);
    }
  }, [checkoutStep, hasNoUserPassengers]);

  useEffect(() => {
    if (reservation) {
      history.push(PATH_BOOK_CONFIRMATION);
    }
  }, [reservation]);

  React.useEffect(() => {
    const getCustomerDetails = async () => {
      try {
        const details = await fetchCustomerDetails();
        setContact({
          email: "",
          ...contactInfo,
          phoneNumber: details?.phoneNumber || "",
        });
        setContactInfo(contactInfo?.email || "", details?.phoneNumber || "");
      } finally {
        setCustomerDetailsLoading(false);
      }
    };

    getCustomerDetails();
    isTravelWalletOfferExperiment && fetchApplicableTravelWalletItems();
    isTravelCreditHistoryExperiment && fetchTravelWalletCreditHistory();
  }, []);

  useEffect(() => {
    if (
      checkoutStep === MobileCarBookWorkflowStep.Review &&
      !rewardsPaymentAccountReferenceId
    ) {
      handleGoBack();
    }
  }, [checkoutStep, rewardsPaymentAccountReferenceId]);

  useEffect(() => {
    if (priceQuoteInProgress && !!priceQuote) {
      setPriceQuote(null);
    }
  }, [priceQuoteInProgress]);

  const isUnderAge = (driverAge || 0) < 25;

  return (
    <>
      <Box
        className={clsx("corp-mobile-car-book-workflow-root", {
          "mobile-review-car-book":
            checkoutStep === MobileCarBookWorkflowStep.Review,
        })}
      >
        {/* TODO: Header should have pricing details */}
        <Header
          className="mobile-car-book-header"
          left={
            <BackButton
              className="mobile-car-book-header-go-back"
              onClick={handleGoBack}
            />
          }
          right={<PriceBreakdownDropdown />}
          isMobile
          fullWidth
        />
        <LaunchBanner
          isBusinessLive={
            (sessionInfo as CorpSessionInfo)?.corporateInfo.businessIsLive
          }
          onClick={() => {
            trackEvent({
              eventName: "complete_setup_clicked",
              properties: { entry: "traveler_activation_banner" },
            });
          }}
        />
        <Box className="title-summary-container">
          <Typography variant="h6" className="title">
            {TITLE}
          </Typography>
          <Typography variant="body1" className="subtitle">
            {SUBTITLE}
          </Typography>
        </Box>
        <CarBookSummaryPanel
          isMobile
          hidePriceAndReward
          showPolicyBanner
          isInPolicy={isInPolicy}
        />
        <Box className="car-shop-map-container">
          {selectedVehicle && (
            <CarShopSmallMap selectedVehicle={selectedVehicle} isMobile />
          )}
        </Box>
        <CarBookPassengerSelection
          progress={travelerWorkflowStep}
          setProgress={setTravelerWorkflowStep}
          onGoBack={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileCarBookWorkflowStep.TravelerInformation:
                handleGoBack();
                break;
              case MobileCarBookWorkflowStep.Review:
                // note: when the user changes passengers on the final step and then clicks the go-back button,
                // handle it as if the user clicked continue;
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                break;
            }
          }}
          onContinue={(travelersChanged) => {
            switch (checkoutStep) {
              case MobileCarBookWorkflowStep.Review:
                if (travelersChanged) {
                  startSchedulePriceQuoteAndResetPayment();
                }
                break;
              default:
                incrementCheckoutStep();
                break;
            }
          }}
          selectionScreenHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onTravelersStep={
            checkoutStep === MobileCarBookWorkflowStep.TravelerInformation
          }
          isMobile
          useLocalId={true}
        />
        <ContactInfoWorkflow
          titles={{
            contactInfoTitle: CONTACT_INFO_TITLE,
            contactInfoSubtitle: CONTACT_INFO_SUBTITLE,
            buttonTitle:
              checkoutStep === MobileCarBookWorkflowStep.Review
                ? CONTACT_INFO_SAVE
                : CONTINUE,
          }}
          progressStep={contactInfoStep}
          setProgressStep={setContactInfoStep}
          isMobile
          contactInfo={contactInfo}
          onGoBack={() => {
            if (checkoutStep === MobileCarBookWorkflowStep.ContactInformation) {
              handleGoBack();
            }
          }}
          onContactInfoChange={(contactInfo) => {
            setContact(contactInfo);
            setContactInfo(contactInfo.email, contactInfo.phoneNumber);
            if (
              contactInfo.email &&
              contactInfo.phoneNumber &&
              emailRegex.test(contactInfo.email) &&
              phoneRegex.test(contactInfo.phoneNumber)
            ) {
              trackEvent({
                eventName: ADD_CONTACT_INFO,
                properties: {},
              });
            }
            incrementCheckoutStep();
          }}
          mobileHeaderElement={<PriceBreakdownDropdown />}
          className="b2b"
          onContinueClick={
            isHertzProvider
              ? () => {
                  setHertzLoyaltyStep(HertzLoyaltyStep.HertzLoyaltyForm);
                }
              : () => {
                  schedulePriceQuote(
                    history,
                    isAgentPortal ? AGENT_FEE : 0,
                    true
                  );
                  setOpenPaymentCard(true);
                }
          }
          loading={customerDetailsLoading}
        />
        {isHertzProvider && (
          <HertzLoyaltyWorkflow
            progressStep={hertzLoyaltyStep}
            setProgressStep={setHertzLoyaltyStep}
            onGoBack={() => {
              if (checkoutStep === MobileCarBookWorkflowStep.HertzRewards) {
                handleGoBack();
              }
            }}
            onContinueClick={() => {
              schedulePriceQuote(history, isAgentPortal ? AGENT_FEE : 0, true);
              setOpenPaymentCard(true);
              incrementCheckoutStep();
            }}
            mobileHeaderElement={<PriceBreakdownDropdown />}
            showErrors={showErrors}
            setShowErrors={setShowErrors}
            hertzLoyaltyRewardsNumber={hertzLoyaltyRewardsNumber}
          />
        )}
        {isTravelWalletOfferExperiment &&
          !isCreditAndOfferStackingExperimentV1 && (
            <TravelOfferSelection
              progressStep={travelWalletSelectStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              isMobile
              onGoBack={() => {
                if (
                  checkoutStep ===
                  MobileCarBookWorkflowStep.TravelOfferSelection
                ) {
                  handleGoBack();
                }
              }}
              onContinueClick={() => {
                incrementCheckoutStep();
                isTravelWalletPaymentOnly &&
                  setTravelWalletSelectStep(
                    TravelWalletSingleSelectionStep.Main
                  );
              }}
            />
          )}
        <PriceBreakdown />
        <Box
          className={clsx("underage-banner-container", {
            "less-padding": isTreesModalExperiment,
          })}
        >
          {isUnderAge && (
            <NotificationBanner
              className="underage-banner"
              label={UNDER_25_WARNING}
              severity={BannerSeverity.INFO}
              icon={
                <Icon className="banner-icon" name={IconName.InfoCircleFill} />
              }
            />
          )}
        </Box>
        {!isInPolicy && (
          <>
            <ApprovalReasonWorkflow
              buttonText="Review my trip"
              progressStep={approvalReasonStep}
              setProgressStep={setApprovalReasonStep}
              mobileHeaderElement={<PriceBreakdownDropdown />}
              onGoBack={handleGoBack}
              className="b2b approval-reason-workflow"
              onContinueClick={(reason) => {
                incrementCheckoutStep();
                setRequestReason(reason);
              }}
              corporateTravel={selectedVehicle?.corporateTravel}
            />
            <Box p="16px 16px 50px 16px">
              <ApprovalBanner
                isApprovalRequired={
                  isApprovalsV2Enabled
                    ? policies?.settings && policies.settings.isApprovalRequired
                    : !isAutoApprovalEnabled
                }
                isApprovalsV2Enabled={isApprovalsV2Enabled}
              />
            </Box>
          </>
        )}

        {isTreesModalExperiment && (
          <TreesModal
            image={TreesConfirmation}
            header={TREES_MODAL_HEADER}
            title={TREES_MODAL_TITLE}
            onClose={() => setTreeModalOpen(false)}
            subtitle={TREES_MODAL_SUBTITLE}
            icon={<Icon className="trees-icon" name={IconName.TreesIcon} />}
            openModal={treeModalOpen}
            setOpenModal={setTreeModalOpen}
            modalLinkCopy={TREES_MODAL_CTA_TEXT}
            modalButtonCopyStartIcon={
              <Icon className="trees-icon" name={IconName.TreesIcon} />
            }
            isMobile
            boldedModalLinkCopy={TREES_BOLDED_MODAL_CTA_TEXT}
          />
        )}

        <CarBookMobileButton
          isInPolicy={isInPolicy}
          hasContactInfo={!!contactInfo}
          openContactInfo={() => {
            setContactInfoStep(ContactInfoStep.ContactInfoForm);
          }}
          isBookingValid={isBookingValid}
          // TODO: CA-2198 -
          // Once approval v2 is turned on and trip request is gone, only call onBookHotel if isApprovalsV2Enabled is true
          onConfirmAndBook={
            isInPolicy || (isApprovalsV2Enabled && isShoppingCartGroundEnabled)
              ? onBookCar
              : onSubmitForApproval
          }
          // TODO: simplify/trim CarBookMobileButton logic
          showPaymentStep
          onApplyRewards={() => {}}
        />
      </Box>
      <MobilePaymentCardPopover
        openPaymentCard={openPaymentCard}
        disabled={!isBookingValid}
        onClose={() => setOpenPaymentCard(false)}
        onConfirm={() => {
          incrementCheckoutStep();
          setOpenPaymentCard(false);
        }}
        onGoBack={() => {
          if (checkoutStep === MobileCarBookWorkflowStep.RewardsAndPayment) {
            handleGoBack();
          }
        }}
        mobileHeaderElement={<PriceBreakdownDropdown />}
        isInPolicy={isInPolicy}
      />
      <LoadingPopup
        classes={["mobile-price-quote-loading-popup"]}
        open={priceQuoteInProgress}
        message={PRICE_QUOTE_MESSAGE}
        indicator={B2BSpinner}
        indicatorSize="small"
        verticalAlignment="center"
        fullScreen
      />
    </>
  );
};

interface IMobilePaymentCardPopoverProps {
  openPaymentCard: boolean;
  disabled?: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onGoBack: () => void;
  mobileHeaderElement?: JSX.Element;
  isInPolicy: boolean;
}

const MobilePaymentCardPopover = (props: IMobilePaymentCardPopoverProps) => {
  const {
    openPaymentCard,
    disabled = true,
    onClose,
    onConfirm,
    onGoBack,
    mobileHeaderElement,
    isInPolicy,
  } = props;

  return (
    <MobilePopoverCard
      open={openPaymentCard}
      className={clsx("car-payment-card-popup", "b2b")}
      fullScreen
      onClose={onClose}
      topLeftButton={
        <BackButton
          className="car-payment-card-popup-back-button"
          onClick={onGoBack}
        />
      }
      headerElement={mobileHeaderElement}
    >
      <PaymentCard />
      {!disabled && (
        <MobileFloatingButton
          className={clsx("car-book-review-trip-button", "b2b")}
          onClick={onConfirm}
          wrapperClassName="b2b"
        >
          {isInPolicy ? REVIEW_MY_TRIP_TEXT : CONTINUE}
        </MobileFloatingButton>
      )}
    </MobilePopoverCard>
  );
};
